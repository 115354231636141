import React from 'react'
import { graphql } from 'gatsby'
import queryString from 'query-string'
import { DEFAULT_LIST_PAGE } from '../constants'

import { PageLayout, SearchPage } from '../components'

const SearchPageTemplate = ({ data, pageContext }) => {
  const { lang, page } = pageContext

  const navigation = { page: DEFAULT_LIST_PAGE }
  if (typeof window !== 'undefined') {
    const qs = queryString.parse(window.location.search)
    navigation.page = qs.page ? +qs.page : navigation.page
  }

  return (
    <PageLayout lang={lang} switcher={page} disableSearch={true}>
      <SearchPage
        page={page}
        products={data.allShopProduct.edges.map(({ node }) => node)}
        attributes={data.allShopAttribute.edges.map(i => i.node)}
        attribute_groups={data.allShopAttributeGroup.edges.map(i => i.node)}
        navigation={navigation}
        lang={lang} />
    </PageLayout>
  )
}

export default SearchPageTemplate

export const query = graphql`
  query SearchPageTemplateQuery {
    allShopProduct(filter: {active: {eq: 1}}) {
      edges {
        node {
          ...ProductSearchItem
        }
      }
    }
    allShopAttributeGroup {
      edges {
        node {
          group_type
          id_attribute_group
          is_color_group
          name_en
          name_nl
          position
          public_name_en
          public_name_nl
        }
      }
    }
    allShopAttribute {
      edges {
        node {
          id_attribute
          id_attribute_group
          color
          name_en
          name_nl
          position
        }
      }
    }
  }
`
